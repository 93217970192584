import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import CCTLargelogo from '../CARMAXCCT_large.png';
import DrawerCustom from '../component/Drawer';
import '../index.css';
import { Typography } from '@mui/material';
import KmxLogo from '../CarMax-Logo.png';


export default function LandingPage() {

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <DrawerCustom />
            <div class="landingDiv">
                <Grid container xs={12} sx={{ marginTop: 6 }}>
                    <Grid item xs={4}>
                        <img src={CCTLargelogo} alt="CCTLogo" className='landingLogo' />
                    </Grid>
                    <Grid item xs={8} sx={{}}>
                        <Typography variant='h1' className='landingPara' sx={{ fontSize: '2.7vw', lineHeight: '1.1', marginTop: '4%', textAlign: 'left' }}>Contact Center Technology Web App</Typography>
                        <Typography variant='subtitle1' className='landingPara' sx={{ marginRight: '7%', textAlign: 'left', fontSize: '1vw' }}>Carmax's Contact Center Technology Team handles all things related to our contact centers, to the IVR system or Carmax. We utilize multiple applications to make this possible, and this Web App is the front door for our customers to our team and its functionalities.</Typography>
                    </Grid>
                </Grid>
            </div>
        </Box>
    );
}

